<template>
  <div class="grid-x grid-margin-x frame-cards">
    <div class="cell medium-6">
      <div class="frame-card frame-loan-calculation">
        <div class="frame-content">
          <h2>Calculez votre capacité d'emprunt</h2>
          <p>
            Notre calculette de simulation de crédit vous permet d’estimer votre budget en 2
            clics.
          </p>
          <app-button theme="secondary" @click="$router.push({ name: 'calculator-budget' })">Calculer maintenant</app-button>
        </div>
        <div class="frame-img">
          <img
            src="@/assets/img/pages/home-calculator.png"
            alt="Calculez votre capacité d'emprunt"
          />
        </div>
      </div>
    </div>
    <div class="cell medium-6">
      <div class="frame-card frame-invest">
        <div class="frame-content">
          <h2>Vous souhaitez investir dans l’immobilier locatif ?</h2>
          <p>
            Un placement rentable que seul un constructeur comme Maisons Alysia peut proposer.
          </p>
          <app-button theme="secondary" @click="$router.push({ name: 'project-house-rental-invest' })">Découvrir notre offre</app-button>
        </div>
        <div class="frame-img">
          <img
            src="@/assets/img/pages/home-invest.png"
            alt="Vous souhaitez investir dans l’immobilier locatif ?"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'frame-cards',
};
</script>

<style lang="sass" scoped>
.frame-cards
  margin-top: 3rem

  .frame-card
    height: 100%
    box-sizing: border-box
    padding: 2.5rem 4rem
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 8px
    .frame-content
      text-align: center
      h2, p
        color: $white
      button
        margin: 2rem auto 0
    .frame-img
      margin-top: 2rem
    &.frame-loan-calculation
      background: $primary
    &.frame-invest
      background: $colored-bg

  @media (max-width: 768px)
    margin-top: 1.5rem

    & > .cell + .cell
      margin-top: 1rem

    .frame-card
      padding: 2.75rem 1rem
      flex-direction: column-reverse
</style>
